.modal-popup {
  display: grid;
  justify-items: center;
  z-index: 5000000;
  background: white;
  border-radius: 23px;
  padding: 30px 50px 50px 50px;
  max-width: 1200px;
  position: fixed;
  width: 80%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-height: 750px;
  height: 100vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.close-modal-popup {
  justify-self: flex-end;
}

.modal-popup > h2 {
  margin-top: 10px;
  margin-bottom: 20px;
  color: #50bdff;
  text-align: center;

  font-family: Random Grotesque Standard Bold;
  font-size: 45px;
  font-weight: 700;
}

.modaltitle {
  color: black;
}

.modal-popup > p {
  color: #50bdff;
  text-align: center;
  font-family: Formular;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 35px;
}

.close-modal-popup {
  cursor: pointer;
}

.modal-popup-continue {
  cursor: pointer;
  padding: 4px 42.023px;
  border-radius: 11.752px;
  background: var(--dark-blue, #50bdff);

  color: #fff;
  font-family: Formular;
  font-size: 21px;
  font-weight: 700;
  max-height: 60px;
}

.ua-flag {
  position: absolute;
  width: 155px;
  height: 75px;
  border-radius: 23px 0px;
  /* transform: rotate(-45deg); */
  background: linear-gradient(
    to bottom,
    #1b9ee5 0%,
    #1b9ee5 49.99%,
    #f4dc00 50%,
    #f4dc00 100%
  );
  top: 0;
  left: 0;
}

@media all and (max-width: 768px) {
  .modal-popup {
    max-height: 1000px;
    height: 90vh;
    top: 2.5%;
    padding: 30px 10px 30px 10px;
    width: 90%;
  }

  .modal-popup > h2 {
    font-size: 32px;
  }

  .modal-popup > p {
    font-size: 18px;
  }

  .ua-flag {
    width: 80px;
    height: 75px;
  }

  .close-modal-popup {
    display: none;
  }
}

@media all and (max-width: 360px) {
  .modal-popup > h2 {
    font-size: 32px;
  }

  .modal-popup > p {
    font-size: 13px;
  }

  .modal-popup > img {
    height: 60px;
  }
}
